<template >
    <div>
        <AddField :addInformation="{title:'Project'}"></AddField>
    </div>
</template>
<script>
import AddField from "../Common/AddField.vue"
export default {
    components: {
    AddField,
  },
}
</script>
<style >
    
</style>